.donate-btn {
    font: 'Raleway';
    text-align: center;
    display: flex;
    align-items: center;
}

.navvisible div:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.navvisible div:nth-child(2) {
    opacity: 0;
    visibility: hidden;
    transform: translateX(100%);
}

.navvisible div:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.navhidden {}

@keyframes showMenuDropdown {
    0% {
        height: 0;
        opacity: 0;
    }

    100% {
        height: 400px;
        opacity: 1;
    }
}

@keyframes hideMenuDropdown {

    0% {
        height: 400px;
        opacity: 1;
    }

    100% {
        height: 0;
        opacity: 0;
    }
}

.showMenu {
    /* height: fit-content; */
    opacity: 1;
    animation: showMenuDropdown 0.3s ease forwards;
    transition: all 0.6s ease;
}

.hideMenu {
    animation: hideMenuDropdown 0.3s ease forwards;
    transition: all 0.3s ease;
    height: 0;
    opacity: 0;
    overflow: hidden;
    /* Ensures content doesn't overflow while collapsing */
}


@media (min-width:990px) {

    #i6,
    #i7 {
        display: none;
    }
}