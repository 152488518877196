

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.notificationCard {
    width: 220px;
    height: 280px;
    background: rgb(245, 245, 245);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 35px;
    gap: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123);
    border-radius: 20px;
  }
  
  .bellIcon {
    width: 50px;
    margin: 20px 0px;
  }
  
  .bellIcon path {
    fill: rgb(168, 131, 255);
  }
  
  .notificationHeading {
    color: black;
    font-weight: 600;
    font-size: 0.8em;
  }
  
  .notificationPara {
    color: rgb(133, 133, 133);
    font-size: 0.6em;
    font-weight: 600;
    text-align: center;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .AllowBtn {
    width: 120px;
    height: 25px;
    background-color: rgb(168, 131, 255);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 0.7em;
    font-weight: 600;
    cursor: pointer;
  }
  
  .NotnowBtn {
    width: 120px;
    height: 25px;
    color: rgb(168, 131, 255);
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.7em;
    cursor: pointer;
    border-radius: 20px;
  }
  
  .NotnowBtn:hover {
    background-color: rgb(239, 227, 255);
  }
  
  .AllowBtn:hover {
    background-color: rgb(153, 110, 255);
  }
  
